<template>
    <div class="campus_schedule schedule-calendar">
        <!-- <schedule v-model="active" @change="onScheduleChange"></schedule> -->
        <vue-hash-calendar 
        ref="calendar"
        @click="handleClick"
        @change="handleChange"
        @slidechange="handleSlidechange"
        checkedDayClassName="schedule-calendar-color"
        format="YY-MM-DD"
        :visible="true" 
        :isShowArrow="false" 
        :isShowAction="false" 
        :isShowWeekView="isShowWeekView"
        :scrollChangeDate="true"
        :markDate="markDate">
        <!-- <div @click="isShowWeeks" slot="arrow">
          <img v-if="isShowWeekViews" src="@/assets/download.png">
          <img v-else src="@/assets/download-2.png">
        </div> -->
      </vue-hash-calendar>
        <div>
            <van-search
                v-model="student_name"
                class="search"
                placeholder="学员名称"
                @search="onSearch"
                @clear="onCancel"
                >
            </van-search>
        </div>
        <div class="journal-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <van-cell @click="toPath(item)" is-link v-for="item in list" :key="item.book_id">
                    <template #title>
                        <div class="journal-info">
                            <div>
                                <avatar :src="item.student_avatar" color="#fff" :size="36"></avatar>
                            </div>
                            <div>
                                <div>{{item.student_name}}</div>
                                <div>
                                    <van-tag round type="warning" v-if="item.teacher_feedback==0">未点评</van-tag>
                                    <van-tag round type="success" v-if="item.teacher_feedback==1">已点评</van-tag>
                                    <!-- <van-tag round :text-color="?'#fff':''" :color="item.teacher_feedback?'#00cca2':'#f4f4f4'">{{item.teacher_feedback?'已点评':'未点评'}}</van-tag> -->
                                </div>
                            </div>
                        </div>
                    </template>
                </van-cell>
            </van-list>
        </div>
        <!-- <div>
            <div class="campus_schedule_search">
                <van-cell-group>
                    <van-cell @click="handleClick('showCalendar')" title="上课时间" is-link :value="`${dateValue.length === 0 ? '' : `${dayjs(dateValue[0]).format('MM/DD')} - ${dayjs(dateValue[1]).format('MM/DD')}`}`" />
                </van-cell-group>
            </div>
            <div class="campus_schedule_item">
                <van-cell-group>
                    <van-cell @click="toPath(item)" v-for="(item,index) of list" :key="index" :label="`${item.course_name}`" :value="item.teacher">
                        <template #title>
                            <span>{{`${dayjs(item.class_date).format('MM/DD【dddd】')} ${item.class_time}`}}</span>
                        </template>
                        <template #label>
                            <van-tag plain type="success">{{`${item.reserve_count}`}}人</van-tag>
                            <img v-if="item.is_summary" class="campus_schedule_item-img" src="@/assets/beike-active.png" alt="">
                            <span class="custom-title">{{`${item.course_name}`}}</span>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
        </div> -->
        <!-- <van-calendar v-model="showCalendar" color="#00cca2" :default-date="defaultDate" :min-date="minDate" :max-date="maxDate" type="range" @confirm="onConfirmCalendar" /> -->
        <van-empty v-if="list.length == 0" description="暂无签到学员信息." />
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import 'dayjs/locale/zh-cn'
    import Schedule from '@/components/Schedule'
    // import Schedule from './schedule'
    import no_order from '@/assets/no_order.png'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    import {Search, Cell, CellGroup, DatetimePicker, Picker, Calendar, Checkbox, CheckboxGroup, Field, Tag, Empty } from 'vant'
    export default {
        name:"campusScheduleIndex",
        data() {
            return {
                no_order,
                loading:false,
                showCalendar:false,
                studioColumns:[],
                studio: {},
                class_date:dayjs().format('YYYY-MM-DD'),
                active: dayjs().format('YYYY-MM-DD'),
                dateValue:[dayjs().add(-7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD')],
                defaultDate:[dayjs().add(-7,'day').$d, dayjs().$d],
                finished: false,
                list:[],
                listFinishedText: '',
                student_name:'',
                studio_id:'',
                teacher:{},
                teachers:[],
                minDate: dayjs().add(-1,'year').$d,
                maxDate: dayjs().$d,
                forenoon:1,
                afternoon:1,
                evening:1,
                weeks:[],
                pageSize: 10,
                pageCurrent: 1,
                showPicker: false,
                searchLoading:false,
                isShowWeekView:true,
                isShowWeekViews:true,
                markDate:[],
            }
        },
        components: {
            [Tag.name]:Tag,
            [Field.name]:Field,
            [Cell.name]:Cell,
            loadings:Loadings,
            [Picker.name]:Picker,
            [Checkbox.name]:Checkbox,
            [CheckboxGroup.name]:CheckboxGroup,
            [Calendar.name]:Calendar,
            [CellGroup.name]:CellGroup,
            [DatetimePicker.name]:DatetimePicker,
            [Search.name]: Search,
            [Avatar.name]: Avatar,
            [Empty.name]: Empty,
            schedule: Schedule,
        },
        async created () {
            dayjs.locale('zh-cn')
            await this.getList()
        },
        methods: {
            dayjs,
            onLoad() {
                // this.getList()
            },
            async getList(){
                this.loading = true
                await this.$api.employee_schedule_wait_for_comment({start_date:this.class_date,end_date:this.class_date,sort:'teacher_feedback',search:{fuzzy:this.student_name},'per-page': this.pageSize, page: this.pageCurrent})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onScheduleChange(value) {
                this.class_date = value.date
                this.searchLoading = true
                this.pageCurrent = 1
                this.getList()
            },
            handleClick(name,num){
                this[name] = true
            },
            onConfirmCalendar(date){
                this.dateValue = [dayjs(date[0]).format('YYYY-MM-DD'),dayjs(date[1]).format('YYYY-MM-DD')]
                this.showCalendar = false
                this.pageCurrent = 1
                this.getList()
            },
            toPath(item){
                this.$router.push({path:'/teacher/comment_to_student/detail',query:{book_id:item.book_id,student_id:item.student_id}})
            },
            onSearch(val) {
              this.pageCurrent = 1
              this.getList()
            },
            onCancel() {
              this.pageCurrent = 1
              this.student_name = ''
              this.getList()
            },
            handleClick(e){
                // console.log(e);
                this.class_date=e;
                this.pageCurrent = 1
                this.getList();
                // for(let i=0; i<this.weeks.length;i++){
                //     if(this.weeks[i].date == e){
                //         this.list = this.weeks[i].courses
                //         break
                //     }else{
                //         this.list = []
                //     }
                // }
            },
            handleChange(e){
                this.active = e
                this.start_date = dayjs(dayjs(e).startOf('month')).add(-1, 'month').format('YYYY-MM-DD')
                this.end_date = dayjs(dayjs(e).endOf('month')).add(1, 'month').format('YYYY-MM-DD')
            },
            handleSlidechange(e){
                if (e=='up' || e=='down') return false
                let that = this
                setTimeout(()=>{
                    that.getList()
                },0)
            },
            isShowWeeks(){
                this.isShowWeekViews = !this.isShowWeekViews
                // localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
            },
            onConfirm(val,index){
                if(this.pickerIndex != index){
                    this.pickerIndex = index
                    this.pageCurrent = 1
                    this.getList()
                }
                this.showPicker = false
            }
        },
    }
</script>
<style lang="less">
    .journal{
        // background: #fff;
        &-info{
            display: flex;
            align-items: center;
            position: relative;
            .vue-avatar--wrapper{
                margin-right: 10px;
            }
            .crm-customer-phone{
                display: flex;
                align-items: center;
                position: absolute;
                right: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                justify-content: center;
                background: rgb(244,244,244);
            }
        }
        .journal-list{
            .van-cell{
                align-items: center;
                background-color: rgba(0,0,0,0);
            }
            .van-tag--default{
                margin-right: 5px;
                color: #888;
                font-size: 10px;
            }
        }
    }
</style>
<style lang="less">
  .schedule-calendar{
    .calendar_dot{
      width: 4PX;
      height: 4PX;
    }
    .calendar_content{
      overflow: initial;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
    }
    .calendar_first_today{
      color:#00cca2;
    }
  }
</style>
